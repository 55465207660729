// src/components/commonComponent/Form.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/Form.css';

const Form = ({ fields, onSubmit, mode, initialData }) => {
  const [formData, setFormData] = useState(initialData || {});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      {fields.map(field => (
        <div className="form-group" key={field.name}>
          <label htmlFor={field.name}>{field.label}</label>
          {field.type === 'select' ? (
            <select
              className="form-control"
              id={field.name}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              required={field.required}
            >
              <option value="" disabled>{field.placeholder}</option>
              {field.options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          ) : field.type === 'radio' ? (
            field.options.map(option => (
              <div key={option.value} className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id={`${field.name}-${option.value}`}
                  name={field.name}
                  value={option.value}
                  checked={formData[field.name] === option.value}
                  onChange={handleChange}
                  required={field.required}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${field.name}-${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            ))
          ) : field.type === 'checkbox' ? (
            field.options.map(option => (
              <div key={option.value} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`${field.name}-${option.value}`}
                  name={option.value}
                  checked={formData[option.value] || false}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${field.name}-${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            ))
          ) : (
            <input
              type={field.type || 'text'}
              className="form-control"
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name]}
              onChange={handleChange}
              required={field.required}
            />
          )}
        </div>
      ))}
      <button type="submit" className="btn btn-primary">{mode === 'add' ? 'Add' : 'Update'} Data</button>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  initialData: PropTypes.object,
};

export default Form;
