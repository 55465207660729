// src/pages/appointments/AddAppointment.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Form from '../../components/commonComponent/Form'; // Adjust path as needed
import { fetchData } from '../../utils/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL


const AddAppointment = () => {
  const [therapists, setTherapists] = useState([]);
  const [patients, setPatients] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    fetchData(`${API_URL}/getAlltherapistList`, setTherapists, setError);
    fetchData(`${API_URL}/getAllUsers`, setPatients, setError);
  }, []);

  const fields = [
    { label: 'Appointment Date', name: 'appointmentDate', placeholder: 'Enter appointment Date', required: true },
    {
      label: 'Therapist',
      name: 'therapistId',
      placeholder: 'Select therapist',
      type: 'select',
      options: therapists.map((therapist) => ({
        value: therapist._id,
        label: `${therapist.email}`,
      })),
      required: true,
    },
    {
      label: 'Patient',
      name: 'patientId',
      placeholder: 'Select patient',
      type: 'select',
      options: patients.map((patient) => ({
        value: patient._id,
        label: `${patient.email}`,
      })),
      required: true,
    },
  ];

  const addAppointment = async (formData) => {

    try {
      const response = await axios.post(`${API_URL}/addAppointment`, formData);
      return response.data;
    } catch (error) {
      console.error("Error adding appointment:", error);
      throw error;
    }
  };

  const handleClose = () => {
    navigate('/doc-appointment')
  }

  const handleSubmit = async (formData) => {
    try {
      const result = await addAppointment(formData);
      toast.success('Data added successfully',{
        autoClose: 2000
      })
      setTimeout(() => {
        navigate('/home')
      }, 2000)
    } catch (error) {
        toast.error('Error adding data', {
            autoClose: 2000
          })
    }
  };

  return (
    <div className='form-wrapper'>
      <div className='form-header'>
      <h2 className='form-title'>Add Appointment</h2>
      <button className="form-close-btn" onClick={handleClose}>&times;</button>

      </div>
      <Form
        mode="add"
        onSubmit={handleSubmit}
        fields={fields}
      />
    </div>
  );
};

export default AddAppointment;
