// src/router/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TherapistFormProvider } from '../context/FormContext';
import { registrationSchema, loginSchema } from '../utils/validationSchema';
import Layout from '../components/Layout';
import Dashboard from '../pages/dashboard/Dashboard';
import Login from '../pages/login/Login';
import Register from '../pages/register/Register';
import Therapist from '../pages/therapist/Therapist';
import Patient from '../pages/patient/Patient';
import DocAppointment from '../pages/appointment/DocAppointment';
import Notification from '../pages/notification/Notification';
import { AuthProvider } from '../context/AuthContext';
import PublicRoute from './publicRoutes';
import PrivateRoute from './privateRoutes';
import TherapistAdd from '../pages/therapist/TherapistAdd';
import TherapistUpdate from '../pages/therapist/TherapistUpdate';
import PatientAdd from '../pages/patient/PatientAdd';
import PatientUpdate from '../pages/patient/PatientUpdate';
import AppointmentAdd from '../pages/appointment/AppointmentAdd';
import AppointmentUpdate from '../pages/appointment/AppointmentUpdate';
import PatientForm from '../pages/patient/PatientForm';
import Questionnare from '../pages/questionnare/Questionnare';
import Profile from '../pages/profile/Profile';

const AppRouter = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/' element={
            <PublicRoute>
              <TherapistFormProvider schema={loginSchema}>
                <Login />
              </TherapistFormProvider>
            </PublicRoute>
          }/>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <TherapistFormProvider schema={loginSchema}>
                  <Login />
                </TherapistFormProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <TherapistFormProvider schema={registrationSchema}>
                  <Register />
                </TherapistFormProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Layout>
                  <Profile />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist"
            element={
              <PrivateRoute>
                <Layout>
                  <Therapist/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist/add"
            element={
              <PrivateRoute>
                <Layout>
                  <TherapistAdd/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/therapist/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <TherapistUpdate/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient"
            element={
              <PrivateRoute>
                <Layout>
                  <Patient/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/add"
            element={
              <PrivateRoute>
                <Layout>
                  <PatientAdd/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <PatientUpdate/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/form/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <PatientForm/>
                </Layout>
              </PrivateRoute>
            }
          />
         
          <Route
            path="/doc-appointment/add"
            element={
              <PrivateRoute>
                <Layout>
                  <AppointmentAdd/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/doc-appointment/edit/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <AppointmentUpdate/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/doc-appointment"
            element={
              <PrivateRoute>
                <Layout>
                  <DocAppointment/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <PrivateRoute>
                <Layout>
                  <Notification/>
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/questionnare"
            element={
              <PrivateRoute>
                <Layout>
                  <Questionnare/>
                </Layout>
              </PrivateRoute>
            }
          />
       
          {/* Add more routes here */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default AppRouter;
