// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Therapist.css */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 /* background-color: #1B1F2E;  Matching header color */
  color: #FF7900;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-header h2 {
  margin: 0;
}

.page-header .btn-primary {
  background-color: #FF7900; /* Accent color */
  border-color: #FF7900;
}

.page-header .btn-primary:hover {
  background-color: #e67600; /* Darker shade */
  border-color: #e67600;
}


.table-container {
  margin: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #1B1F2E; /* Header background */
  color: #fff;
}

.table td, .table th {
  padding: 0.75rem;
  text-align: left;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #e9ecef;
}

.table button {
  background: none;
  border: none;
  cursor: pointer;
  color: #FF7900; /* Accent color */
}

.table button:hover {
  color: #e67600; /* Darker shade */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Therapist.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;CACpB,sDAAsD;EACrD,cAAc;EACd,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,qBAAqB;AACvB;;AAEA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,qBAAqB;AACvB;;;AAGA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,qBAAqB;EACrB,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB,EAAE,sBAAsB;EACjD,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc,EAAE,iBAAiB;AACnC;;AAEA;EACE,cAAc,EAAE,iBAAiB;AACnC","sourcesContent":["/* Therapist.css */\n.page-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n /* background-color: #1B1F2E;  Matching header color */\n  color: #FF7900;\n  padding: 1rem 2rem;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.page-header h2 {\n  margin: 0;\n}\n\n.page-header .btn-primary {\n  background-color: #FF7900; /* Accent color */\n  border-color: #FF7900;\n}\n\n.page-header .btn-primary:hover {\n  background-color: #e67600; /* Darker shade */\n  border-color: #e67600;\n}\n\n\n.table-container {\n  margin: 1rem 2rem;\n  background-color: #fff;\n  border-radius: 0.5rem;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 1rem;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table th {\n  background-color: #1B1F2E; /* Header background */\n  color: #fff;\n}\n\n.table td, .table th {\n  padding: 0.75rem;\n  text-align: left;\n}\n\n.table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.table tr:hover {\n  background-color: #e9ecef;\n}\n\n.table button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  color: #FF7900; /* Accent color */\n}\n\n.table button:hover {\n  color: #e67600; /* Darker shade */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
