import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Therapist.css";
import {
  fetchData,
  deleteData,
  handlePageChange,
  getCurrentData,
} from "../../utils/utils";
import useModal from "../../hooks/useModal";
import Pagination from "../../components/commonComponent/Pagination";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import Table from "../../components/commonComponent/Table";
import ViewModal from "../../components/commonComponent/ViewModal";
import { toast } from "react-toastify";

// Get the API URL from the environment variable
const API_URL = process.env.REACT_APP_API_URL

const columns = [
  { header: "First Name", accessor: "firstName" },
  { header: "Last Name", accessor: "lastName" },
  { header: "State", accessor: "state" },
  { header: "City", accessor: "city" },
  { header: "Specialities", accessor: "specialities" },
];

const ITEMS_PER_PAGE = 10;

const Therapist = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("API_URL:", API_URL); // Debugging line
    fetchData(`${API_URL}/getAlltherapistList`, setData, setError);
  }, []);

  const handleDeleteConfirm = () => {
    deleteData(
      `${API_URL}/deleteDoctorById`,
      selectedItem._id,
      setError
    )
      .then(() => {
        // Remove item from the data state after successful deletion
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        toast.success("Deleted successfully", { autoClose: 2000 });
      })
      .catch((error) => {
        setError(error);
        toast.error("Error deleting data", { autoClose: 2000 });
      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(data, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    'First Name' : 'firstName',
    'Last Name' : 'lastName' ,
    'Email' : 'email', 
    'Speacialities' : 'specialities',

    'State' : 'state',
    'City' : 'city',
  }

  return (
    <div className="therapist-page">
      <div className="page-header">
        <h2>Therapist List</h2>
        <Link to="/therapist/add" className="btn btn-primary">
          Add Therapist
        </Link>
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          data={currentData}
          showIndex={true}
          currentPage={currentPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onDelete={handleOpenDeleteModal}
          onUpdate={(item) => navigate(`/therapist/edit/${item._id}`)}
          onView={handleOpenViewModal}
          showEdit={true}
          showForm={false}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange(setCurrentPage)}
        />
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem}
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default Therapist;
