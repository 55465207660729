import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { TherapistFormProvider } from "./context/FormContext";
import AppRouter from "./router/AppRouter";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <ToastContainer />
      <AuthProvider>
        <TherapistFormProvider>
          <AppRouter />
        </TherapistFormProvider>
      </AuthProvider>
      ,
    </>
  );
}

export default App;
