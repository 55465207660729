// src/forms/RegistrationForm.js
import React from 'react';
import { useFormContext } from '../context/FormContext';
import { Input } from '../components/commonComponent/FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RegistrationForm = () => {
  const methods = useFormContext();
const {  register } = useAuth()
const navigate = useNavigate();
  const onSubmit = async(data) => {
    const success = await register(data.username, data.email, data.password);
    if (success) {
      navigate('/login');
    } else {
      console.error('Registration failed');
    }


  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      {/* <Input
        label="First Name"
       controlProp={{name: "firstName", control: methods.control}}
        placeholder="Enter your first name"
      />
      <Input
        label="Last Name"
        controlProp={{ name: "lastName", control: methods.control }}
        placeholder="Enter your last name"
      /> */}
      <Input
        label="UserName"
        controlProp={{ name: "username", control: methods.control }}
        placeholder="Enter your Username"
        type="name"
      />
      <Input
        label="Email"
        controlProp={{ name: "email", control: methods.control }}
        placeholder="Enter your email"
        type="email"
      />
      <Input
        label="Password"
        controlProp={{ name: "password", control: methods.control }}
        placeholder="Enter your password"
        type="password"
      />
      <Input
        label="Confirm Password"
        controlProp={{ name: "confirmPassword", control: methods.control }}
        placeholder="Confirm your password"
        type="password"
      />
      <button type="submit">Register</button>
      <Link to='/'>Already have an account? Login</Link>
    </form>
  );
};

export default RegistrationForm;
