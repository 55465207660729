// src/forms/LoginForm.js
import React from 'react';
import { useFormContext } from '../context/FormContext';
import { Input } from '../components/commonComponent/FormInput';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = () => {
  const methods = useFormContext();
  const { login } = useAuth();
const navigate = useNavigate()
  const onSubmit = async(data) => {
    const success = await login(data.email, data.password);
    if (success) {
      toast.success('Login successful', {
        autoClose: 2000
      })
      setTimeout(() => {
        navigate('/home');
      }, 1000);
    } else {
      console.error('Login failed');
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Input
        label="Email"
        controlProp={{ name: "email", control: methods.control }}
        placeholder="Enter your email"
        type="email"
      />
      <Input
        label="Password"
        controlProp={{ name: "password", control: methods.control }}
        placeholder="Enter your password"
        type="password"
      />
      <button type="submit">Login</button>
      <Link to="/register">Don't have an account? Create Account</Link>
    </form>
  );
};

export default LoginForm;
