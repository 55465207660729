import React from 'react';
import styled from 'styled-components';
import Card from '../../components/commonComponent/Card';

const DashboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Card title="Total Users" value="1,200" />
      <Card title="Active Groups" value="50" />
      <Card title="Upcoming Events" value="10" />
      <Card title="New Registrations" value="25" />
      {/* Add more cards as needed */}
    </DashboardContainer>
  );
};

export default Dashboard;
