// src/pages/Therapist.js
import React, { useEffect, useState } from "react";
import Table from "../../components/commonComponent/Table";
import "../../styles/Therapist.css";
import Pagination from "../../components/commonComponent/Pagination";
import useModal from "../../hooks/useModal";
import {
  deleteData,
  fetchData,
  getCurrentData,
  handlePageChange,
} from "../../utils/utils";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import ViewModal from "../../components/commonComponent/ViewModal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const API_URL = process.env.REACT_APP_API_URL;


const columns = [
  { header: "First Name", accessor: "firstName" },
  { header: "Last Name", accessor: "lastName" },
  { header: "State", accessor: "state" },
  { header: "City", accessor: "city" },
  { header: "Email", accessor: "email" },
];

const ITEMS_PER_PAGE = 10;

const Patient = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);


  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();
  const navigate = useNavigate();
  useEffect(() => {
    fetchData(`${API_URL}/getAllUsers`, setData, setError);
  }, []);

  const handleDeleteConfirm = () => {
    deleteData(
      `${API_URL}/deleteUserById`,
      selectedItem._id,
      setError
    )
      .then(() => {
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        toast.success("Deleted successfully", { autoClose: 2000 });
      })
      .catch((error) => {
        setError(error);
        toast.error("Error deleteing data", { autoClose: 2000 });
      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(data, currentPage, ITEMS_PER_PAGE);

  // const fieldsToShow = ["firstName", "lastName", "email", "state", "city"]
  const fieldsToShow = {
    'First Name' : 'firstName',
    'Last Name' : 'lastName' ,
    'Email' : 'email', 
    'State' : 'state',
    'City' : 'city',

  }

  return (
    <div className="therapist-page">
      <div className="page-header">
        <h2>Patient List</h2>
        <Link to="/patient/add" className="btn btn-primary">
          Add Patient
        </Link>
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          data={currentData}
          showIndex={true}
          currentPage={currentPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onDelete={handleOpenDeleteModal}
          onUpdate={(item) => navigate(`/patient/edit/${item._id}`)}
          onView={handleOpenViewModal}
          onForm={(item) => navigate(`/patient/form/${item._id}`)}
          showForm={true}
          showEdit={true} // Ensure the edit icon is shown
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange(setCurrentPage)}
        />
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem}
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default Patient;
