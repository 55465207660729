// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-item {
  margin: 0 0.25rem;
}

.page-link {
  border: 1px solid #FF7900; /* Accent color */
  color: #FF7900;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #fff;
}

.page-link:hover {
  background-color: #FF7900;
  color: #fff;
}

.page-item.disabled .page-link {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-item.active .page-link {
  background-color: #FF7900;
  color: #fff;
  border-color: #FF7900;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Pagination.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,sBAAsB;EACtB,qBAAqB;EACrB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,cAAc;EACd,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB","sourcesContent":["/* Pagination.css */\n.pagination {\n  display: flex;\n  justify-content: center;\n  padding: 1rem 0;\n  background-color: #fff;\n  border-radius: 0.5rem;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.page-item {\n  margin: 0 0.25rem;\n}\n\n.page-link {\n  border: 1px solid #FF7900; /* Accent color */\n  color: #FF7900;\n  padding: 0.5rem 0.75rem;\n  border-radius: 0.25rem;\n  cursor: pointer;\n  background-color: #fff;\n}\n\n.page-link:hover {\n  background-color: #FF7900;\n  color: #fff;\n}\n\n.page-item.disabled .page-link {\n  cursor: not-allowed;\n  opacity: 0.5;\n}\n\n.page-item.active .page-link {\n  background-color: #FF7900;\n  color: #fff;\n  border-color: #FF7900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
