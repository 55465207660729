// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set the background color for the entire page */

body {
    background-color: #fff0f5; /* Lavender blush background */
  }
  
  


.form-box {
background-color: #ffffff; /* White background */
border: 1px solid #ddd; /* Light border */
border-radius: 8px; /* Rounded corners */
padding: 20px; /* Padding inside the box */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
display: flex;
flex-direction: column;
align-items: center; /* Center content horizontally */
}

.form-box h2 {
font-size: 24px;
font-weight: bold;
}

.form-box input {
margin-bottom: 10px; /* Space between input fields */
width: 100%; /* Full-width inputs */
}

.form-box button {
width: 100%; /* Full-width button */
background-color: #007bff; /* Bootstrap primary color */
color: white;
}

.form-box button:hover {
background-color: #0056b3; /* Darker shade on hover */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Register.css"],"names":[],"mappings":"AAAA,iDAAiD;;AAEjD;IACI,yBAAyB,EAAE,8BAA8B;EAC3D;;;;;AAKF;AACA,yBAAyB,EAAE,qBAAqB;AAChD,sBAAsB,EAAE,iBAAiB;AACzC,kBAAkB,EAAE,oBAAoB;AACxC,aAAa,EAAE,2BAA2B;AAC1C,wCAAwC,EAAE,kBAAkB;AAC5D,aAAa;AACb,sBAAsB;AACtB,mBAAmB,EAAE,gCAAgC;AACrD;;AAEA;AACA,eAAe;AACf,iBAAiB;AACjB;;AAEA;AACA,mBAAmB,EAAE,+BAA+B;AACpD,WAAW,EAAE,sBAAsB;AACnC;;AAEA;AACA,WAAW,EAAE,sBAAsB;AACnC,yBAAyB,EAAE,4BAA4B;AACvD,YAAY;AACZ;;AAEA;AACA,yBAAyB,EAAE,0BAA0B;AACrD","sourcesContent":["/* Set the background color for the entire page */\n\nbody {\n    background-color: #fff0f5; /* Lavender blush background */\n  }\n  \n  \n\n\n.form-box {\nbackground-color: #ffffff; /* White background */\nborder: 1px solid #ddd; /* Light border */\nborder-radius: 8px; /* Rounded corners */\npadding: 20px; /* Padding inside the box */\nbox-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */\ndisplay: flex;\nflex-direction: column;\nalign-items: center; /* Center content horizontally */\n}\n\n.form-box h2 {\nfont-size: 24px;\nfont-weight: bold;\n}\n\n.form-box input {\nmargin-bottom: 10px; /* Space between input fields */\nwidth: 100%; /* Full-width inputs */\n}\n\n.form-box button {\nwidth: 100%; /* Full-width button */\nbackground-color: #007bff; /* Bootstrap primary color */\ncolor: white;\n}\n\n.form-box button:hover {\nbackground-color: #0056b3; /* Darker shade on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
