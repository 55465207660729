import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../components/commonComponent/Form'; // Adjust path as needed
import { toast } from 'react-toastify';


const API_URL = process.env.REACT_APP_API_URL;


const fields = [
  { label: 'First Name', name: 'firstName', placeholder: 'Enter first name' },
  { label: 'Last Name', name: 'lastName', placeholder: 'Enter last name' },
  { label: 'Email', name: 'email', placeholder: 'Enter email', type: 'email' },
  { label: 'State', name: 'state', placeholder: 'Enter state' },
  { label: 'City', name: 'city', placeholder: 'Enter city' },
  // Add other fields as needed
];

const updateDoctor = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/updateDrInfo/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error updating doctor:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const fetchDoctorById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/getDetailOfDoctorById/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching doctor by ID:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const TherapistUpdate = () => {
  const { id } = useParams(); // Retrieve the ID from URL parameters
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (id) { // Ensure id is available before fetching data
      const loadDoctorData = async () => {
        try {
          const data = await fetchDoctorById(id);
          setInitialData(data);
        } catch (error) {
          // Handle error if needed
        }
      };
      loadDoctorData();
    }
  }, [id]);

  const handleClose = () => {
    navigate('/therapist')
  }

  const handleSubmit = async (formData) => {
    try {
      const result = await updateDoctor(id, formData);
      toast.success('Data updated successfully',{
        autoClose: 2000
      })
      setTimeout(() => {
        navigate('/therapist')
      }, 2000)
    } catch (error) {
      toast.error('Error updating data', {
        autoClose: 2000
      })
    }
  };

  return (
    <div className='form-wrapper'>
      <div className='form-header'>
      <h2 className='form-title'>Update Therapist</h2>
      <button className="form-close-btn" onClick={handleClose}>&times;</button>

      </div>
      {initialData ? (
        <Form
          mode="update"
          onSubmit={handleSubmit}
          fields={fields}
          initialData={initialData}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TherapistUpdate;
