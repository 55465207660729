import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const getNestedValue = (obj, path) => {
  if (!obj) return 'N/A';
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const ViewModal = ({ show, onHide, data, fields }) => {
  if (!data) return null;

  const isObject = typeof data === 'object' && !Array.isArray(data);

  if (!isObject) {
    // If data is not an object, map the array to an object using fields as keys
    const mappedData = Object.keys(fields).reduce((acc, key, index) => {
      acc[key] = data[index] || 'N/A';
      return acc;
    }, {});
    data = mappedData;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(fields).map(([label, path]) => (
          <p key={label}>
            <strong>{label}:</strong> {getNestedValue(data, path) || 'N/A'}
          </p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewModal;
