import React from 'react';
import '../../styles/Login.css';
import LoginForm from '../../forms/LoginForm';

const Login = () => (
  <div className="container mt-5 login-container">
    <div className="row justify-content-center">
      <div className='col-md-6'>
        <div className="form-box p-4 shadow-sm rounded">
          <h2 className="text-center mb-4">Login</h2>
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
);

export default Login;
