import React, { useState } from 'react';
import axios from 'axios';
import Form from '../../components/commonComponent/Form'; // Adjust path as needed
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../styles/Form.css'; // Make sure to import the updated CSS

const API_URL = process.env.REACT_APP_API_URL;

const fields = [
  { label: 'First Name', name: 'firstName', placeholder: 'Enter first name', required: true },
  { label: 'Last Name', name: 'lastName', placeholder: 'Enter last name' },
  { label: 'Email', name: 'email', placeholder: 'Enter email', type: 'email', required: true },
  { label: 'State', name: 'state', placeholder: 'Enter state' },
  { label: 'City', name: 'city', placeholder: 'Enter city' },
  // Add other fields as needed
];

const addDoctor = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/adduserInfo`, formData);
    return response.data;
  } catch (error) {
    console.error("Error adding doctor:", error);
    throw error; // Rethrow error to handle it in the component
  }
};

const PatientAdd = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/patient'); // Navigate to the desired page when the form is closed
  };

  const handleSubmit = async (formData) => {
    try {
      const result = await addDoctor(formData);
      toast.success('Data added successfully', {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate('/patient');
      }, 2000);
    } catch (error) {
      toast.error('Error adding data', {
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="form-wrapper">
      <div className="form-header">
        <h2 className="form-title">Add Patient</h2>
        <button className="form-close-btn" onClick={handleClose}>&times;</button>
      </div>
      <Form
        mode="add"
        onSubmit={handleSubmit}
        fields={fields}
      />
    </div>
  );
};

export default PatientAdd;
