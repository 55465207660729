import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Therapist.css";
import { fetchData, deleteData, handlePageChange, getCurrentData } from "../../utils/utils";
import useModal from "../../hooks/useModal";
import Pagination from "../../components/commonComponent/Pagination";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import Table from "../../components/commonComponent/Table";
import ViewModal from "../../components/commonComponent/ViewModal";
import { toast } from "react-toastify";


const API_URL = process.env.REACT_APP_API_URL


const columns = [
  { header: "Message", accessor: "message" },
  { header: "Therapist Email", accessor: "therapistEmail" },
  { header: "Patient Email", accessor: "patientEmail" },
  { header: "Status", accessor: "status" },
];

const ITEMS_PER_PAGE = 10;

const Notification = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const {
    showDeleteModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();
  const navigate = useNavigate();

  const processData = (notifications) => {
    return notifications.map(notification => ({
      ...notification,
      therapistEmail: notification.receiverId?.email || 'N/A',
      patientEmail: notification.senderId?.email || 'N/A',
    }));
  };

  useEffect(() => {
    fetchData(`${API_URL}/getNotificationList`, setData, setError, processData);
  }, []);

  const handleDeleteConfirm = () => {
    deleteData(
      `${API_URL}/deleteDoctorById`,
      selectedItem._id,
      setError

    )
      .then(() => {
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        toast.success('Deleted successfully', { autoClose: 2000 })

      })
      .catch((error) => {
        setError(error);
        toast.error('Error deleteing data' , {autoClose: 2000})

      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(data, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    'Therapist First Name': 'receiverId.firstName',
    'Therapist Last Name': 'receiverId.lastName',
    'Therapist Email': 'receiverId.email',
    'Patient First Name': 'senderId.firstName',
    'Patient Last Name': 'senderId.lastName',
    'Patient Email': 'senderId.email',
    'Appointment Date': 'appointmentDate',
    'Status': 'status',
  };

  return (
    <div className="therapist-page">
      <div className="page-header">
        <h2>Notification List</h2>
        {/* <Link to="/therapist/add" className="btn btn-primary">Add Therapist</Link> */}
      </div>
      <div className="table-container">
      <Table
        columns={columns}
        data={currentData}
        showIndex={true}
        currentPage={currentPage}
        itemsPerPage={ITEMS_PER_PAGE}
        onDelete={handleOpenDeleteModal}
        onView={handleOpenViewModal}
        showEdit={false} // Hide edit icon
        showForm={false}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange(setCurrentPage)}
      />
      </div>

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem}
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default Notification;
