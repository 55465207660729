import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(25% - 20px); /* 4 cards in a row with spacing */
`;

const CardTitle = styled.h4`
  margin: 0 0 10px;
  font-size: 1.2rem;
`;

const CardValue = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

const Card = ({ title, value }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <CardValue>{value}</CardValue>
  </CardContainer>
);

export default Card;
