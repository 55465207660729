// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header container styles */
.header-container {
  width: 100%;
  height: 60px;
  background-color: #1B1F2E; /* Dark background */
  color: #FFFFFF; /* White text color */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed; /* Ensure it's fixed at the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Lower than toast container's z-index */
  padding: 0 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(255, 121, 0, 0.3); /* Orange shadow for Header */
}


/* Title styles */
.header-title {
  margin: 0;
  font-size: 1.5rem;
  color: #FF7900; /* Accent color for title */
}

/* Avatar styles */
.header-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #FF7900; /* Accent border color */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB,EAAE,oBAAoB;EAC/C,cAAc,EAAE,qBAAqB;EACrC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe,EAAE,iCAAiC;EAClD,MAAM;EACN,OAAO;EACP,aAAa,EAAE,yCAAyC;EACxD,eAAe;EACf,sBAAsB;EACtB,4CAA4C,EAAE,6BAA6B;AAC7E;;;AAGA,iBAAiB;AACjB;EACE,SAAS;EACT,iBAAiB;EACjB,cAAc,EAAE,2BAA2B;AAC7C;;AAEA,kBAAkB;AAClB;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB,EAAE,wBAAwB;AACrD","sourcesContent":["/* Header container styles */\n.header-container {\n  width: 100%;\n  height: 60px;\n  background-color: #1B1F2E; /* Dark background */\n  color: #FFFFFF; /* White text color */\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: fixed; /* Ensure it's fixed at the top */\n  top: 0;\n  left: 0;\n  z-index: 1000; /* Lower than toast container's z-index */\n  padding: 0 20px;\n  box-sizing: border-box;\n  box-shadow: 0 4px 8px rgba(255, 121, 0, 0.3); /* Orange shadow for Header */\n}\n\n\n/* Title styles */\n.header-title {\n  margin: 0;\n  font-size: 1.5rem;\n  color: #FF7900; /* Accent color for title */\n}\n\n/* Avatar styles */\n.header-avatar {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  object-fit: cover;\n  border: 2px solid #FF7900; /* Accent border color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
