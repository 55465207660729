// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Form.css */

.form-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #1B1F2E;
  color: #ffffff;
  position: relative;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-title {
  color: #FF7900;
  margin: 0;
}

.form-close-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
}

.form-close-btn:hover {
  color: #FF7900;
}

/* Rest of your styles */
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  font-weight: bold;
  color: #FF7900;
}

.form-control {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  color: #1B1F2E;
}

.form-control:focus {
  border-color: #FF7900;
  box-shadow: 0 0 5px rgba(255, 121, 0, 0.5);
}

.btn {
  margin-top: 10px;
  background-color: #FF7900;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #e66800;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Form.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA,wBAAwB;AACxB;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* src/styles/Form.css */\n\n.form-wrapper {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  background-color: #1B1F2E;\n  color: #ffffff;\n  position: relative;\n}\n\n.form-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.form-title {\n  color: #FF7900;\n  margin: 0;\n}\n\n.form-close-btn {\n  background: none;\n  border: none;\n  color: #ffffff;\n  font-size: 24px;\n  cursor: pointer;\n  padding: 0;\n}\n\n.form-close-btn:hover {\n  color: #FF7900;\n}\n\n/* Rest of your styles */\n.form-group {\n  margin-bottom: 1rem;\n}\n\n.form-group label {\n  font-weight: bold;\n  color: #FF7900;\n}\n\n.form-control {\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  padding: 10px;\n  color: #1B1F2E;\n}\n\n.form-control:focus {\n  border-color: #FF7900;\n  box-shadow: 0 0 5px rgba(255, 121, 0, 0.5);\n}\n\n.btn {\n  margin-top: 10px;\n  background-color: #FF7900;\n  border: none;\n  color: #ffffff;\n  padding: 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.btn:hover {\n  background-color: #e66800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
