import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Sidebar.css'; // Import your CSS file

const links = [
  { to: "/home", label: "Dashboard" },
  {
    label: 'Users',
    submenu: [
      { to: '/therapist', label: 'Therapists' },
      { to: '/patient', label: 'Patients' }
    ]
  },
  {
    label: 'Appointments',
    submenu: [
      { to: '/doc-appointment', label: 'Appointment' },
      // { to: '/appointments/patients', label: 'Patients' }
    ]
  },
  { to: "/notification", label: "Notification" },
  { to: "/Questionnare", label: "Questionnare"}
];

const Sidebar = () => {
  const [openMenus, setOpenMenus] = useState({});

  const handleMenuToggle = (label) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [label]: !prevState[label]
    }));
  };

  return (
    <div className="sidebar-container">
      {links.map((link, index) => {
        const isSubmenu = Array.isArray(link.submenu);
        const isOpen = openMenus[link.label] || false;

        return (
          <React.Fragment key={index}>
            {isSubmenu ? (
              <>
                <div 
                  className={`sidebar-link ${isOpen ? 'active' : ''}`}
                  onClick={() => handleMenuToggle(link.label)}
                >
                  {link.label}
                </div>
                <div className={`submenu-container ${isOpen ? 'open' : ''}`}>
                  {link.submenu.map((subLink, subIndex) => (
                    <Link 
                      key={subIndex} 
                      className="submenu-link" 
                      to={subLink.to}
                    >
                      {subLink.label}
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <Link className="sidebar-link" to={link.to}>
                {link.label}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Sidebar;
