import React from 'react';
import '../styles/Layout.css'; // Import your CSS file
import Header from './commonComponent/Navbar';
import Sidebar from './commonComponent/Sidebar';

const Layout = ({ children }) => (
  <div className="layout-container">
    <Sidebar />
    <main className="main-content">
      <Header />
      {children}
    </main>
  </div>
);

export default Layout;
