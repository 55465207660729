// src/components/header/Header.js
import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import '../../styles/Header.css';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  const handleProfile = () => {
    navigate('/profile')
  }

  return (
    <header className="header-container">
      <h1 className="header-title">Therapist</h1>
      <Dropdown>
        <Dropdown.Toggle as="div" id="dropdown-basic" style={{ cursor: 'pointer' }}>
          {/* <img className="header-avatar" src={user.image || '/images/defaultProfile.jpg'} alt="Profile" /> */}
          {user && user.username}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleProfile}>My Profile</Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
};

export default Header;
