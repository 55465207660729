import React from 'react'
import '../../styles/Register.css'
import RegistrationForm from '../../forms/RegistrationForm'
const Register = () => (
    <div className="container mt-5">
        <div className="row justify-content-center">
            <div className='col-md-6'>
               <div className="form-box p-4 shadow-sm rounded">
                <h2 className="text-center mb-4">Register</h2>
                <RegistrationForm />
               </div>
            </div>
        </div>
    </div>
)


export default Register
