// src/pages/PatientForm.js
import React from "react";
import Form from "../../components/commonComponent/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";



const API_URL = process.env.REACT_APP_API_URL


const fields = [
  {
    name: "therapyType",
    label: "Type of Therapy",
    type: "radio",
    options: [
      { value: "individual", label: "Individual" },
      { value: "group", label: "Group" },
    ],
    required: true,
  },
  {
    name: "gender",
    label: "Gender",
    type: "radio",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    required: true,
  },
  {
    name: "ageGroup",
    label: "Age Group",
    type: "select",
    placeholder: "Select your age group",
    options: [
      { value: "under18", label: "Under 18" },
      { value: "18-24", label: "18-24" },
      { value: "25-34", label: "25-34" },
      { value: "35-44", label: "35-44" },
      { value: "45-54", label: "45-54" },
      { value: "55-64", label: "55-64" },
      { value: "65Plus", label: "65+" },
    ],
    required: true,
  },
  {
    name: "relationshipStatus",
    label: "Relationship Status",
    type: "checkbox",
    options: [
      { value: "single", label: "Single" },
      { value: "married", label: "Married" },
      { value: "widowed", label: "Widowed" },
      { value: "divorced", label: "Divorced" },
      { value: "others", label: "Others" },
    ],
    required: true,
  },
  {
    name: "sport",
    label: "Which sport do you play ?",
    type: "select",
    placeholder: "Select your sport",
    options: [
      { value: "Football", label: "Football" },
      { value: "Hockey", label: "Hockey" },
      { value: "Cricket", label: "Cricket" },
      { value: "Badminton", label: "Badminton" },
      { value: "Golf", label: "Golf" },
    ],
    required: true,
  },
  {
    name: "therapyEver",
    label: "Have you ever been to therapy ?",
    type: "radio",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "therapyReason",
    label: "What led you to therapy today",
    type: "checkbox",
    options: [
      { value: "single", label: "Mental Health Issues" },
      { value: "married", label: "Sleep Disorders" },
      { value: "widowed", label: "Financial Stress" },
      { value: "divorced", label: "Physical Health Issues" },
    ],
    required: true,
  },
  {
    name: "therapyExpectations",
    label: "What are your expectations from the therapist ?",
    type: "checkbox",
    options: [
      { value: "single", label: "Confidentiality" },
      { value: "married", label: "Active Listening" },
      { value: "widowed", label: "Clear Communication" },
      { value: "divorced", label: "Flexibility" },
    ],
    required: true,
  },
  {
    name: "physicalHealth",
    label: "How would you rate your current physical health ?",
    type: "radio",
    options: [
      { value: "", label: "Good" },
      { value: "No", label: "Fair" },
      { value: "No", label: "Bad" },
    ],
    required: true,
  },
  { label: 'Illness or under any medication ?', name: 'medication', placeholder: 'Please Specify if any' },
  {
    name: "routines",
    label: "Trouble concentrating on daily life routines?",
    type: "select",
    placeholder: "Select your sport",
    options: [
      { value: "Football", label: "Not at all" },
      { value: "Hockey", label: "Several Days" },
      { value: "Cricket", label: "More than 2 weeks" },
      { value: "Badminton", label: "Nearly every day" },
    ],
    required: true,
  },
  {
    name: "employmentStatus",
    label: "Employment status",
    type: "radio",
    options: [
      { value: "", label: "Employed" },
      { value: "No", label: "Unemployed" },
      { value: "No", label: "Self Employed" },
    ],
    required: true,
  },
  {
    name: "drinkSmoke",
    label: "How often do you drink alcohol or smoke ciggerettes",
    type: "radio",
    options: [
      { value: "", label: "Never" },
      { value: "No", label: "Infrequently" },
      { value: "No", label: "Monthly" },
      { value: "No", label: "Daily" },
    ],
    required: true,
  },
  {
    name: "sleeping",
    label: "How would you rate your current sleeping habits ?",
    type: "radio",
    options: [
      { value: "", label: "Good" },
      { value: "No", label: "Fair" },
      { value: "No", label: "Bad" },
    ],
    required: true,
  },
  {
    name: "communicationMode",
    label: "Mode of communication with the therapist?",
    type: "radio",
    options: [
      { value: "", label: "Message" },
      { value: "No", label: "On Call" },
      { value: "No", label: "Virtual Meeting" },
    ],
    required: true,
  },
  {
    name: "preference",
    label: "Any specific preference of therapist ?",
    type: "radio",
    options: [
      { value: "", label: "Male" },
      { value: "No", label: "Female" },
      { value: "No", label: "Highly Experienced" },
    ],
    required: true,
  },


];

const addPatientProfile = async (id, formData) => {
    try {
      const response = await axios.put(`${API_URL}/updateProfile/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error adding doctor:", error);
      throw error; // Rethrow error to handle it in the component
    }
  };

const PatientForm = () =>  {
    const { id } = useParams(); // Retrieve the ID from URL parameters

    const navigate = useNavigate();


    const handleForm = async (formData) => {
        try {
          const result = await addPatientProfile(id, formData);
          toast.success('Data added successfully',{
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/patient')
          }, 2000)
        } catch (error) {
          toast.error('Error adding data', {
            autoClose: 2000
          })
        }
      };

      const handleClose = () => {
        navigate('/patient'); // Navigate to the desired page when the form is closed
      };
  return (
    <div className="form-wrapper">
      <div className="form-header">
      <h2 className="form-title">Patient Information</h2>
      <button className="form-close-btn" onClick={handleClose}>&times;</button>

      </div>
      <Form fields={fields} onSubmit={handleForm} mode="add"  />
    </div>
  );
};

export default PatientForm;
