import React, { useEffect, useState } from 'react';
import Table from '../../components/commonComponent/Table';
import '../../styles/Therapist.css';
import Pagination from "../../components/commonComponent/Pagination";
import useModal from "../../hooks/useModal";
import { deleteData, fetchData, getCurrentData, handlePageChange } from "../../utils/utils";
import DeleteConfirmationModal from '../../components/commonComponent/DeleteConfirmationModal';
import { Link, useNavigate } from 'react-router-dom';
import ViewModal from '../../components/commonComponent/ViewModal';
import { toast } from 'react-toastify';


const API_URL = process.env.REACT_APP_API_URL


const columns = [
  { header: 'Therapist Email', accessor: 'therapistEmail' },
  { header: 'Patient Email', accessor: 'patientEmail' },
  { header: 'Appointment Date', accessor: 'appointmentDate' },
  { header: 'Status', accessor: 'status' },
];

const ITEMS_PER_PAGE = 10;

const DocAppointment = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { 
    showDeleteModal, 
    showViewModal, 
    selectedItem, 
    handleOpenDeleteModal, 
    handleCloseDeleteModal, 
    handleOpenViewModal, 
    handleCloseViewModal 
  } = useModal();

  useEffect(() => {
    const processData = (appointments) => {
      return appointments.map(appointment => {
        const processedAppointment = {
          ...appointment,
          therapistEmail: appointment.receiverId?.email || 'N/A',
          patientEmail: appointment.senderId?.email || 'N/A',
          appointmentDate: new Date(appointment.appointmentDate).toISOString().split('T')[0],
        };
        console.log('Processed Appointment:', processedAppointment); // Debug processed data
        return processedAppointment;
      });
    };

    fetchData(`${API_URL}/getAllAppointments/`, setData, setError, processData);
  }, []);

  useEffect(() => {
    console.log('State Data:', data); // Debug state data
  }, [data]);

  const handleDeleteConfirm = () => {
    deleteData(`${API_URL}/deleteUserById`, selectedItem._id, setError)
      .then(() => {
        setData((prevData) => prevData.filter(item => item._id !== selectedItem._id));
        toast.success('Deleted successfully', { autoClose: 2000 })

      })
      .catch((error) => {
        setError(error);
        toast.error('Error deleteing data' , {autoClose: 2000})

      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(data, currentPage, ITEMS_PER_PAGE);


  const fieldsToShow = {
    'Therapist First Name': 'receiverId.firstName',
    'Therapist Last Name': 'receiverId.lastName',
    'Therapist Email': 'receiverId.email',
    'Patient First Name': 'senderId.firstName',
    'Patient Last Name': 'senderId.lastName',
    'Patient Email': 'senderId.email',
    'Appointment Date': 'appointmentDate',
    'Status': 'status',
  };
  

  return (
    <div className="therapist-page">
      <div className="page-header">
        <h2>Appointment List</h2>
        <Link to="/doc-appointment/add" className="btn btn-primary">Add Appointment</Link>
      </div>
      <div className='table-container'>
        <Table
          columns={columns}
          data={currentData}
          showIndex={true}
          currentPage={currentPage}
          itemsPerPage={ITEMS_PER_PAGE}
          onDelete={handleOpenDeleteModal}
          onUpdate={(item) => navigate(`/doc-appointment/edit/${item._id}`)}
          onView={handleOpenViewModal}
          showEdit={false} // Ensure the edit icon is shown
          showForm={false}

        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange(setCurrentPage)}
        />
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      {selectedItem && (
        <ViewModal
          show={showViewModal}
          onHide={handleCloseViewModal}
          data={selectedItem} // Pass the selected item data to the modal
          fields={fieldsToShow}
        />
      )}
    </div>
  );
};

export default DocAppointment;
